<template>
    <div style="display: flex;height: 100vh;">
        <div style="width: 220px; height: 100%;  background-color:#545c64;">

            <el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
                background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
                <!-- <el-submenu index="1">
                        <template slot="title">
                            <i class="el-icon-location"></i>
                            <span>导航一</span>
                        </template>
<el-menu-item-group>
    <template slot="title">分组一</template>
    <el-menu-item index="1-1">选项1</el-menu-item>
    <el-menu-item index="1-2">选项2</el-menu-item>
</el-menu-item-group>
<el-menu-item-group title="分组2">
    <el-menu-item index="1-3">选项3</el-menu-item>
</el-menu-item-group>
<el-submenu index="1-4">
    <template slot="title">选项4</template>
    <el-menu-item index="1-4-1">选项1</el-menu-item>
</el-submenu>
</el-submenu> -->
                <el-menu-item index="2">
                    <i class="el-icon-menu"></i>
                    <span slot="title">首页</span>
                </el-menu-item>
                <!-- <el-menu-item index="3" disabled>
                        <i class="el-icon-document"></i>
                        <span slot="title">导航三</span>
                    </el-menu-item>
                    <el-menu-item index="4">
                        <i class="el-icon-setting"></i>
                        <span slot="title">导航四</span>
                    </el-menu-item> -->
            </el-menu>

        </div>
        <div style="height: 100%;">
            <div style="padding: 30px 50px;">
                <el-form ref="loginForm" :inline="true" :model="loginForm" label-position="left">
                    <el-form-item prop="zh" label="关键词:">
                        <el-input v-model="loginForm.keyWord" placeholder="手机号/姓名姓名/邮箱/标题"></el-input>
                    </el-form-item>
                    <el-form-item prop="zh" label="注册时间:">
                        <el-date-picker v-model="loginForm.value1" type="datetimerange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item prop="zh" label="是否报名:">
                        <el-select v-model="loginForm.enroll" placeholder="请选择" clearable>
                            <el-option v-for="item in options2" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="zh" label="是否下架:">
                        <el-select v-model="soldOut" placeholder="请选择" clearable>
                            <el-option v-for="item in options3" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onrest">重置</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="exportDatabutton">批量导出</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div style="width: calc(100vw - 220px);margin: 10px auto;text-align: center; padding: 0 50px;">
                <el-table :data="tableData" style="width: 100%;height: 100%;" height="720"
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column prop="id" label="编号" width="180" />
                    <el-table-column prop="artworkTitle" label="标题" width="180" />
                    <el-table-column prop="enroll" label="状态" width="180">
                        <template slot-scope="scope">
                            <div v-if="scope.row.isDelete == 1">已下架</div>
                            <div v-if="scope.row.isDelete == 0">未下架</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="enroll" label="报名状态" width="180">
                        <template slot-scope="scope">
                            <div v-if="scope.row.enroll == 1">已报名</div>
                            <div v-if="scope.row.enroll == 0">未报名</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="authorName" label="用户姓名" width="180">
                    </el-table-column>
                    <el-table-column prop="phone" label="手机号" width="180">
                    </el-table-column>
                    <el-table-column prop="createdTime" label="注册时间" width="180">
                    </el-table-column>
                    <el-table-column prop="email" label="邮箱" width="180">
                    </el-table-column>
                    <el-table-column prop="source" label="学校" width="180">
                    </el-table-column>
                    <el-table-column prop="actualType" label="分组类别" width="180">
                    </el-table-column>
                    <el-table-column prop="artworkType" label="作品分类">
                    </el-table-column>
                    <el-table-column prop="recommend" label="推荐单位" width="180">
                    </el-table-column>
                    <el-table-column prop="vote" label="投票数" width="100">
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="350">
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="text" size="small">查看作品</el-button>
                            <el-button @click="handleClick2(scope.row)" type="text" size="small">附件下载</el-button>
                            <el-button @click="handleClick3(scope.row)" type="text" size="small"
                                v-if="scope.row.isDelete == 1">上架</el-button>
                            <el-button @click="handleClick3(scope.row)" type="text" size="small" v-else>下架</el-button>
                            <span style="color: red;font-size: 12px;margin-left: 5px;">(仅限于zip,rar,pdf,word,ppt)</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="padding-top: 10px">
                    <el-pagination ref="pagination" background layout="prev, pager, next" prev-text="<" next-text=">"
                        :page-size="currpage" :current-page="page" :total="totalCount" @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { selectPageList, exportData, artworksoldOut } from "../../util/api"
export default {
    name: 'science_child2',
    data() {
        return {
            page: 1,
            currpage: 10,
            totalCount: 0,
            loginForm: {
                keyWord: '',
                value1: '',
                enroll: ''
            },
            options3: [
                {
                    value: 0,
                    label: '未下架'
                },
                {
                    value: 1,
                    label: '已下架'
                }
            ],
            options2: [
                {
                    value: 0,
                    label: '未报名'
                },
                {
                    value: 1,
                    label: '已报名'
                }
            ],
            tableData: [],
            arrid: [],
            soldOut: '',
        }
    },
    mounted() {
    },
    created() {
        this.getlist()
    },
    methods: {
        onrest() {
            this.page = 1
            this.currpage = 10
            this.loginForm = {
                keyWord: '',
                value1: '',
                enroll: ''
            }
            this.soldOut = ''
            this.getlist()
        },
        exportDatabutton() {
            exportData(this.arrid).then((res) => {
                const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = '导出文件.xlsx';
                document.body.appendChild(a);
                a.style.display = "none";
                a.click();
                document.body.removeChild(a);
            })
        },
        handleSelectionChange(val) {
            console.log(val);
            const arr = []
            val.forEach(i => {
                arr.push(i.id)
            });
            this.arrid = arr
        },
        onSubmit() {
            this.page = 1
            this.currpage = 10
            this.getlist()
        },
        onSubmits() { },
        handleClick(val) {
            if (val.enroll == 0) {
                this.$message({
                    message: '还未上传作品！',
                    type: 'warning'
                });
            } else {
                window.open('https://wenchuang-web.123bingo.cn/#/science_child7?id=' + val.id, '_blank');
            }
        },
        handleClick2(val) {
            if (val.file) {
                // window.location.href = val.file
                window.open(val.file)
            } else {
                this.$message({
                    message: '此作品没有附件！',
                    type: 'warning'
                });
            }
        },
        handleClick3(val) {
            this.$confirm('是否下架该作品，下架之后无法进行投票?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                artworksoldOut(val.id).then((res) => {
                    if (res.data.message) {
                        this.$message({
                            message: res.data.message,
                            type: 'warning'
                        });
                    } else {
                        this.$message({
                            message: '提交成功！',
                            type: 'success'
                        });
                        this.getlist()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });

        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        getlist() {
            if (this.loginForm.keyWord) {
                this.page = 1;
            }
            selectPageList({
                page: this.page,
                size: this.currpage,
                // signTimeStart: this.loginForm.value1 ? moment(this.loginForm.value1[0]).format("YYYY-MM-DD HH:mm:ss") : '',
                // signTimeEnd: this.loginForm.value1 ? moment(this.loginForm.value1[1]).format("YYYY-MM-DD HH:mm:ss") : '',
                signTimeStart: this.loginForm.value1 ? this.loginForm.value1[0] : '',
                signTimeEnd: this.loginForm.value1 ? this.loginForm.value1[1] : '',
                keyWord: this.loginForm.keyWord,
                enroll: this.loginForm.enroll,
                soldOut: this.soldOut
            }).then((res) => {
                console.log(res.data);
                this.tableData = res.data.artworkInfoDao;
                this.totalCount = res.data.total;
            })
        },
        handleSizeChange(cpage) {
            this.currpage = cpage;
            this.getlist();
        },
        //分页按钮 首页和最后一页
        handleCurrentChange(cpage) {
            if (this.loginForm.keyWord) {
                this.page = 1;
            } else {
                this.page = cpage;
            }
            this.getlist();
        },
    }
}
</script>
<style lang="less" scoped>
.el-menu {
    border-right: solid 0px #e6e6e6;
}

.science_child4 {
    padding-bottom: 0.8rem;

    .science_title {
        padding-top: 0.6rem;
        font-size: 0.36rem;
        color: #353535;
        font-weight: bold;
        text-align: center;
    }

    .science_title_en {
        color: #999999;
        font-size: 0.18rem;
    }

    .science_Nav {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.3rem;
    }

    .science_NavLi {
        width: 1.18rem;
        height: 0.38rem;
        text-align: center;
        color: #9c9c9c;
        font-size: 0.14rem;
        line-height: 0.38rem;
        border: 1px solid #eeeeee;
        margin: 0 0.1rem;
        cursor: pointer;
    }

    .science_NavLi_active {
        width: 1.18rem;
        height: 0.38rem;
        text-align: center;
        color: #ffffff;
        font-size: 0.14rem;
        line-height: 0.38rem;
        background-color: #ffb900;
        border: 1px solid #ffb900;

    }


    .box1Title {
        width: 9.6rem;
        font-size: 0.24rem;
        color: #333333;
        margin: 0.5rem auto 0;
        line-height: 0.4rem;
        text-align: center;
    }

    .box1Count_div {
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .box1Count_li {
        margin: 0 0.5rem;
        text-align: center;
    }

    .box1Count_liA {
        font-size: 0.48rem;
        color: #ffb900;
    }

    .box1Count_liA span {
        font-size: 0.14rem;
        color: #ffb900;
    }

    .box1Count_liB {
        font-size: 0.14rem;
        color: #222222;
        text-align: center;
    }

    .box1Map {
        position: relative;
        margin-top: 0.8rem;
    }

    .box1Map_img {
        width: 100%;
    }

    .box1Map_li {
        position: absolute;
        width: 1.07rem;
        height: 1.07rem;
        border-radius: 100%;
        border: 1px solid #ffffff;
        cursor: pointer;
        transition: all 0.5s;
        color: transparent;
    }

    .box1Map_li:hover {
        transform: scale(1.3);
        border: 2px solid #ffba05;
        color: #333333;
    }

    .box1Map_img {
        width: 100%;
    }

    .box1Map_text {
        position: absolute;
        height: 1rem;
        bottom: -1.1rem;
        font-size: 0.16rem;
        text-align: center;
        width: 100%;

    }

    .box1_li0 {
        top: 0;
        left: 38%;
    }

    .box1_li1 {
        top: 1rem;
        left: 16%;
    }

    .box1_li2 {
        top: 1rem;
        left: 81%;
    }

    .box1_li3 {
        top: 2rem;
        left: 55%;
    }

    .box1_li4 {
        top: 3rem;
        left: 36%;
    }

    .box1_li5 {
        top: 5.16rem;
        left: 10.35%;
    }

    .box1_li6 {
        top: 5.6rem;
        left: 52.8%;
    }

    .box1_li7 {
        top: 5rem;
        left: 87%;
    }



    .box2list {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-right: -0.6rem;
    }

    .box2li {
        width: 29%;
        height: 5rem;
        border: 1px solid #dfdfdf;
        margin: 0.6rem 0.6rem 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
    }

    .box2li_img {
        height: 40%;
        display: block;
        margin: 0 auto;
    }

    .box2li_line {
        width: 20px;
        height: 2px;
        background-color: #ffb900;
        margin: 0.25rem auto;
    }

    .box2li_text {
        text-align: center;
        color: #393939;
        font-size: 0.2rem;
        font-weight: bold;

    }




    .reportfont {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.6rem;
    }

    .my-btn {
        width: 0.8rem;
        height: 0.4rem;
        line-height: 0.4rem;
        text-align: center;
        background-color: #ffffff;
        color: #868686;
        font-size: 0.14rem;
        border-radius: 0px;
        cursor: pointer;
        border: 1px solid #eeeeee;
    }

    .my-btn span {
        font-size: 0.14rem;
        font-weight: bold;
    }

    .el-pagination {
        text-align: center;
        padding: 0;
    }


    .el-pagination.is-background button {
        width: 0.4rem;
        height: 0.4rem;
        border: 1px solid #eeeeee;
        background-color: #ffffff;
        color: #868686;
        font-size: 0.14rem;
        border-radius: 0px;
    }

    .el-pagination.is-background button:not(.disabled) {
        width: 0.4rem;
        height: 0.4rem;
        border: 1px solid #eeeeee;
        background-color: #ffffff;
        color: #868686;
        font-size: 0.14rem;
        border-radius: 0px;
    }

    .el-pagination.is-background .el-pager li:not(.disabled) {
        width: 0.4rem;
        height: 0.4rem;
        line-height: 0.4rem;
        font-size: 0.14rem;
        background-color: #f4f4f4;
        color: #868686;
        border-radius: 0px;
        font-weight: normal;
    }

    .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #ffb900;
        color: #fff;
    }

    .el-pagination.is-background .el-pager li:not(.disabled):hover {
        color: #ffb900;
    }




    /*当浏览器尺寸小于1660px时候的代码：*/
    @media screen and (max-width: 1680px) {

        .box2li {
            width: 28%;
        }
    }

    @media screen and (max-width: 1560px) {}

    @media screen and (max-width: 1400px) {
        .box2li {
            width: 27%;
            height: 4rem;
        }

        .box2li_img {
            // width: 57%;
        }
    }

    @media screen and (max-width: 1200px) {}

    /* 移动端 */
    @media screen and (max-width: 1024px) {}

    @media screen and (max-width: 768px) {}

    @media screen and (max-width: 414px) {}

    @media screen and (max-width: 375px) {}
}
</style>