import { render, staticRenderFns } from "./culture.vue?vue&type=template&id=f9b9850e&scoped=true"
import script from "./culture.vue?vue&type=script&lang=js"
export * from "./culture.vue?vue&type=script&lang=js"
import style0 from "./culture.vue?vue&type=style&index=0&id=f9b9850e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9b9850e",
  null
  
)

export default component.exports